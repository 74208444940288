import React from "react";
import "../styles/global.css";
import Header from "./header";
import Footer from "./footer";
import ScrollToTop from "./scrollTo";
import { useEffect } from "react";

const Layout = ({ children }) => {

  useEffect(() => {
    const userHasConsent = localStorage.getItem('coockieConsent') === 'true'

    console.log(userHasConsent)
  })

  return (
    <>
      <ScrollToTop />
      <div className="masterContainer">
        <Header />
        <div>{children}</div>
        <Footer />

      </div>
    </>
  );
};

export default Layout;
