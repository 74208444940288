export const mainMenu = [
    {
        title: 'Home',
        path: '#home', 
        targetId: 'home',
    },
    {
        title: 'Service',
        path: '#service', 
        targetId: 'service',
    },
    {
        title: 'About',
        path: '#about',
        targetId: 'about',
    },
    {
        title: 'Contact',
        path: '#contact',
        targetId: 'contact'
    },
]