import React from "react";
import "../styles/footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="container">
        <div className="footerContent">
          <div className="footerMenu-1">
            <p>
              <a href="/policy">Privacy Policy</a>
            </p>
            <p>
              <a href="/terms">Terms of Service</a>
            </p>
          </div>

          <div className="footerMenu-2">
            <p>
              <a
                href="https://www.linkedin.com/in/jkernkhoo/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </p>
            <p>
              <a href="mailto:hr@opusmonkey.com">hr@opusmonkey.com</a>
            </p>
          </div>
        </div>
        <div className="footerCopyright">
          <p>&copy; Opus Monkey {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
