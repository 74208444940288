import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import "../styles/header.css";
import { mainMenu } from "../constants/mainMenu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@mui/material"
import clsx from "clsx";
import { navigate } from "gatsby";
import logoImage from "../images/opusmonkey-transparent.png";


const Header = () => {

  const [isNavOpen, setIsNavOpen] = useState(false);

  const [isScrolled, setIsScrolled] = useState(null);

  const handleScroll = () => {
    if(window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return() => {
      window.removeEventListener("scroll", handleScroll)
    }

  }, [])

  const handleNavOpen = () => {
    setIsNavOpen(true);
  };

  const handleNavClose = () => {
    setIsNavOpen(false);
  };

  const handleNavJob = () => {
    navigate('/job')
    setIsNavOpen(false)
  }
  
  const handleTrainingJob = () => {
    navigate('/training')
    setIsNavOpen(false)
  }

  const scrollTo = (id) => {

    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({behavior: "smooth"})
    }

    console.log(window.location.pathname)
    setIsNavOpen(false)
    
  }

  const scrollFromJob = async (id) => {
    navigate('/')

    setTimeout(() => {
      scrollTo(id)
    }, 500)
    
  }

  return (
    <div>
      {isNavOpen && (
        <div
          className="mobileNavBg"
          aria-label="close menu"
          role="button"
          tabIndex={0}
          onClick={handleNavClose}
          onKeyDown={handleNavClose}
        />
      )}
      <nav className={isScrolled? "navbar scroll-background" : "navbar"}>
        <div className="container">
          <div className="headerContainer">
            <div className="mobileNav">
              <div className="headerLogo">
                <a href="/">
                  <StaticImage
                    src="../images/opusmonkey-transparent.png"
                    loading="eager"
                    width={70}
                    quality={98}
                    formats={["auto", "avif", "webp"]}
                    alt="opusmonkey"
                  />
                </a>
              </div>
              {!isNavOpen && (
                <div className="mobileMenu">
                  <MenuIcon onClick={handleNavOpen}/>
                </div>
              )}
            </div>

            <div>
              <ul className={clsx("headerMenu", isNavOpen && "open")}>
                <div className="mobileCloseButton" >
                  <CloseIcon onClick={() => handleNavClose()}/>
                </div>
                {mainMenu.map((item) => (
                  <li key={item.path} className="menuItem">
                    <button onClick={() => window.location.pathname === '/' ? scrollTo(item.targetId) : scrollFromJob(item.targetId)}>{item.title}</button>
                  </li>
                ))}
                <li className="menuItem" onClick={handleTrainingJob}><button>Training</button></li>
                <li><Button variant="contained" onClick={handleNavJob}>Job</Button></li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
